import * as React from 'react';
import { ComponentsState, SwitchErrorInfo } from 'piral-core';
import { Notifications } from 'piral-notifications';
import { SnowfallContainer } from '../snowfall-api';
import { Loading } from './Loading';
import { Header } from './Header';
import { SidebarToggle } from './SidebarToggle';
import { Sidebar } from './Sidebar';
import { Link } from 'react-router-dom';
import { Toast } from './Toast';

export const components: Partial<ComponentsState> = {
  ErrorInfo: props => (
    <div className="flex justify-center">
      <div className="mt-10 rounded-xl p-3 ms-depth-64">
        <SwitchErrorInfo {...props} />
      </div>
    </div>
  ),
  Layout: ({ children }) => (
    <>
      <SnowfallContainer />
      <Header />
      <SidebarToggle />
      <Sidebar />

      <div className="pt-[130px] lg:pt-18 lg:pl-64 h-screen relative overflow-hidden">
        <div id="app-content" className="p-2 h-full overflow-auto">
          {children}
        </div>
      </div>
      <Notifications />
    </>
  ),
  LoadingIndicator: Loading,
  NotificationsHost: ({ children }) => (
    <div className="absolute bottom-0 mb-3 z-[99] left-3">
      <div className="w-96">{children}</div>
    </div>
  ),
  NotificationsToast: Toast,
  DashboardContainer: ({ children }) => (
    <div className="m-4">
      <div className="grid gap-4 grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))]">{children}</div>
    </div>
  ),
  DashboardTile: ({ children }) => (
    <div className="flex bg-white border shadow-sm rounded-xl dark:bg-vscode-card dark:border-vscode-border transform transition duration-100 hover:scale-105 hover:shadow-xl">
      {children}
    </div>
  ),
  BreadcrumbsContainer: ({ children }) => (
    <ol className="flex items-center whitespace-nowrap min-w-0 mt-1" aria-label="Breadcrumb">
      {children}
    </ol>
  ),
  BreadcrumbItem: ({ children, current, path }) => {
    if (current) {
      return (
        <li className="text-sm font-semibold text-gray-800 truncate dark:text-vscode-text" aria-current="page">
          {children}
        </li>
      );
    } else {
      return (
        <>
          <Link className="text-sm flex items-center text-gray-500 hover:text-vscode-accent" to={path}>
            {children}
          </Link>
          <svg
            className="flex-shrink-0 mx-3 overflow-visible h-2.5 w-2.5 text-gray-400 dark:text-gray-600"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </>
      );
    }
  },
};
