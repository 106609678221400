import * as React from 'react';
import { useAction } from 'piral-core';
import { useTranslate } from 'piral-translate';
import { SnowLevels } from './types';

export function isChristmasTime(): boolean {
  const today = new Date();
  // von Nikolaus (06.12.)
  const inDecember = today.getTime() > new Date(today.getFullYear(), 11, 6).getTime();
  // bis inkl. Hl. 3 König (06.01.)
  const inJanuary = today.getTime() < new Date(today.getFullYear(), 0, 7).getTime();
  return inDecember || inJanuary;
}

const snowFlake = require('./snowflake.svg');

export const SnowfallButton: React.FC = () => {
  const setSnowLevel = useAction('setSnowLevel');
  const levels: SnowLevels[] = ['0', '1', '2', '3'];
  const translate = useTranslate();
  if (isChristmasTime()) {
    return (
      <div className="hs-dropdown relative inline-flex [--placement:bottom-right]">
        <button
          id="hs-dropdown-with-header"
          type="button"
          className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center gap-2 h-[2.375rem] w-[2.375rem] rounded-full font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-xs dark:bg-gray-800 dark:hover:bg-slate-800 dark:text-gray-400 dark:hover:text-white dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800">
          <span className="inline-flex items-center justify-center h-full w-full rounded-full">
            <img alt="snowflake" className="h-5 w-5" src={snowFlake} />
          </span>
        </button>

        <div
          className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[8rem] bg-white shadow-md rounded-lg p-2 dark:bg-vscode-bg dark:border dark:border-vscode-border"
          aria-labelledby="hs-dropdown-with-header">
          {levels.map(level => (
            <div className="mt-2 first:pt-0 last:pb-0" key={level}>
              <button
                className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 w-full"
                onClick={() => setSnowLevel(level)}>
                <img alt="snowflake" className="h-5 w-5" src={snowFlake} />
                {translate('stufe') + ` ${level}`}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
