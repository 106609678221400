import React from 'react';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useTranslate } from 'piral-translate';
import { storage } from 'piral-core';

export const ThemeSwitchButton = () => {
  const translate = useTranslate();

  const [darkMode, setDarkMode] = React.useState(() => {
    const isDarkMode = storage.getItem('theme') === 'dark';
    if (isDarkMode) {
      window.document.documentElement.classList.add('dark');
    }
    return isDarkMode;
  });

  const toggleDarkMode = () => {
    const newTheme = darkMode ? 'light' : 'dark';
    storage.setItem('theme', newTheme);
    setDarkMode(!darkMode);

    if (newTheme === 'dark') {
      window.document.documentElement.classList.add('dark');
    } else {
      window.document.documentElement.classList.remove('dark');
    }
  };

  return (
    <div className="hs-tooltip inline-block">
      <button
        className="hs-tooltip-toggle inline-flex flex-shrink-0 justify-center items-center gap-2 h-[2.375rem] w-[2.375rem] rounded-full font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-xs dark:bg-gray-800 dark:hover:bg-slate-800 dark:text-gray-400 dark:hover:text-white dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
        onClick={toggleDarkMode}>
        {darkMode ? <SunIcon className="size-5" /> : <MoonIcon className="size-5" />}
        <span
          className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
          role="tooltip">
          {darkMode ? translate('light-mode') : translate('dark-mode')}
        </span>
      </button>
    </div>
  );
};
