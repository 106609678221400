import React from 'react';

export const Loading = () => {
  return (
    <div className="h-screen w-screen grid place-items-center">
      <div className="animate-spin w-10 h-10 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
