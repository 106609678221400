import { GlobalStateContext } from 'piral-core';
import { snowfallStorageKey } from './constants';
import { SnowLevels } from './types';

export function setSnowLevel(ctx: GlobalStateContext, level: SnowLevels) {
  ctx.dispatch(state => {
    const newState = {
      ...state,
      snowSettings: {
        ...state.snowSettings,
        snowflakeCount: Number(level) * Number(level) * 500,
      },
    };
    localStorage.setItem(snowfallStorageKey, JSON.stringify(newState.snowSettings));
    return newState;
  });
}
