import { KeyIcon } from '@heroicons/react/24/outline';
import { useTranslate } from 'piral-translate';
import React from 'react';

export const LoginButton = ({ keycloak }) => {
  const translate = useTranslate();
  const login = React.useCallback(() => {
    keycloak.login();
  }, []);

  return (
    <button
      className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
      onClick={login}>
      <KeyIcon className="h-5 w-5" />
      {translate('anmelden')}
    </button>
  );
};
