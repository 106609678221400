import * as React from 'react';
import { useGlobalState } from 'piral-core';
import Snowfall from 'react-snowfall';

export const SnowfallContainer: React.FC = () => {
  const settings = useGlobalState(x => x.snowSettings);
  if (settings) {
    return (
      <Snowfall
        color={settings.color}
        snowflakeCount={settings.snowflakeCount}
        radius={settings.radius}
        speed={settings.speed}
        wind={settings.wind}
        style={{ zIndex: 9001 }}
      />
    );
  } else {
    return null;
  }
};
