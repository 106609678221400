import { PiralPlugin } from 'piral-core';
import { KeycloakClient, PiralKeycloakApi } from './types';

/**
 * Creates new Pilet API extensions for enabling keycloak support.
 */
export function createKeycloakApi(client: KeycloakClient): PiralPlugin<PiralKeycloakApi> {
  return context => {
    context.dispatch(state => ({
      ...state,
      keycloak: client.instance,
    }));

    return {
      getKeycloakClient() {
        return context.readState(s => s.keycloak);
      },
    };
  };
}
