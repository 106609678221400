import { KeycloakConfig, KeycloakClient } from './types';
import Keycloak from 'keycloak-js';

export function setupKeycloakClient(config: KeycloakConfig): KeycloakClient {
  const keycloak = new Keycloak({
    url: config.url,
    realm: config.realm,
    clientId: config.clientId,
  });

  // const idpHint = config.idpHint;
  // if (idpHint) {
  //   let login = keycloak.login;
  //   keycloak.login = (option) => {
  //     option.idpHint = idpHint;
  //     return login(option);
  //   };
  //   keycloak.onTokenExpired = function () {
  //     console.warn("onTokenExpired, retry login");
  //     return window.location.href = keycloak.createLoginUrl({
  //       idpHint: idpHint
  //     });
  //   };
  // } else {
  //   keycloak.onTokenExpired = () => {
  //     console.warn("onTokenExpired, retry login");
  //     return window.location.href = keycloak.createLoginUrl();
  //   };
  // }

  const initOptions = {
    onLoad: config.onLoad,
  };

  return {
    instance: keycloak,
    initOptions,
  };
}
