import React from 'react';
import { ErrorComponentsState } from 'piral-core';
import { useTranslate } from 'piral-translate';
import { Link } from 'react-router-dom';

export const errorComponents: Partial<ErrorComponentsState> = {
  not_found: () => {
    const lurchi = require('../assets/lurchi_404.png');
    const translate = useTranslate();

    return (
      <div className="shrink items-center text-center">
        <p className="text-2xl">{translate('error')} 404</p>
        <p className="error">{translate('seite-existiert-nicht-text')}</p>
        <img className="self-center" src={lurchi} alt="Lurchi"></img>
        <p>
          <Link to="/" className="underline">
            {translate('zur-startseite')}
          </Link>
        </p>
      </div>
    );
  },
  loading: () => {
    const lurchi = require('../assets/lurchi_404.png');
    const translate = useTranslate();

    return (
      <div className="shrink items-center text-center">
        <p className="text-2xl">{translate('error')} 504</p>
        <p className="error">{translate('feed-server-offline')}</p>
        <img className="self-center" src={lurchi} alt="Lurchi"></img>
        <p>{translate('bitte-versuche-es-spaeter-nochmal')}</p>
      </div>
    );
  },
};
