import * as React from 'react';
import { MenuRootItem, SipTileAndMenuApi } from './types';
import { SipTile } from './SipTile';
import { MenuType } from 'piral-menu';
import { PiralPlugin } from 'piral-core';
import { SipMenuItem } from './SipMenuItem';

export function createSipletApi(): PiralPlugin<SipTileAndMenuApi> {
  return context => (api, target) => ({
    // Tiles im Dashboard
    registerSipTile(route: string, title: string, description: string, imgSource: string | null) {
      api.registerTile(route, () => <SipTile linkTo={route} title={title} description={description} imgSource={imgSource} />, {
        initialColumns: 2,
        initialRows: 2,
        resizable: true,
      });
    },

    unregisterSipTile(route: string) {
      api.unregisterTile(route);
    },

    // SipMenu (links)
    registerSipMenu(item: MenuRootItem, type: MenuType = 'sidebar') {
      api.registerMenu(item.route, () => <SipMenuItem item={item} />, { type });
    },

    unregisterSipMenu(item: MenuRootItem) {
      api.unregisterMenu(item.route);
    },
  });
}
