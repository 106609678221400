import { usePiletApi } from 'piral-core';
import { PiralNotificationTypes } from 'piral-notifications';
import React from 'react';

export const TestButtons = () => {
  const { showNotification } = usePiletApi();

  const isLocalhost = window.location.hostname === 'localhost';

  const showBrowserNotification = () => {
    if ('Notification' in window) {
      // Überprüfen, ob der Browser Benachrichtigungen unterstützt

      if (Notification.permission === 'granted') {
        // Wenn die Berechtigung bereits erteilt wurde, senden Sie die Benachrichtigung
        new Notification('Benachrichtigung', {
          body: 'Dies ist eine Beispielbenachrichtigung.',
        });
      } else if (Notification.permission !== 'denied') {
        // Ansonsten, wenn die Berechtigung nicht verweigert wurde, um Berechtigung fragen
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            // Wenn die Berechtigung erteilt wird, senden Sie die Benachrichtigung
            new Notification('Benachrichtigung', {
              body: 'Dies ist eine Beispielbenachrichtigung.',
            });
          }
        });
      }
    }
  };

  const showToast = (type: keyof PiralNotificationTypes) => {
    showNotification('Das ist für den Inhalt gedacht, etwas hat nicht funktioniert oder eben doch.', {
      title: 'Neue Toast Benachrichtung',
      type,
    });
  };

  if (isLocalhost) {
    return (
      <div className="hs-dropdown relative inline-flex [--placement:bottom-right]">
        <button
          id="hs-dropdown-with-header"
          type="button"
          className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center gap-2 h-[2.375rem] w-[2.375rem] rounded-full font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-xs dark:bg-gray-800 dark:hover:bg-slate-800 dark:text-gray-400 dark:hover:text-white dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800">
          <p>TEST</p>
        </button>

        <div
          className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[8rem] bg-white shadow-md rounded-lg p-2 dark:bg-vscode-bg dark:border dark:border-vscode-border"
          aria-labelledby="hs-dropdown-with-header">
          <div className="mt-2 first:pt-0 last:pb-0">
            <button
              className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 w-full"
              onClick={showBrowserNotification}>
              Browser-Notification senden
            </button>
          </div>
          <div className="mt-2 first:pt-0 last:pb-0">
            <button
              className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 w-full"
              onClick={() => showToast('error')}>
              Error-Toast senden
            </button>
          </div>
          <div className="mt-2 first:pt-0 last:pb-0">
            <button
              className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 w-full"
              onClick={() => showToast('success')}>
              Success-Toast senden
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
