import React, { useMemo } from 'react';
import { Dict, PageRegistration, useGlobalState } from 'piral-core';
import { useLocation } from 'react-router';
import { MenuProps, PiralMenuItem } from 'piral-menu';

function getPiletName(pathname: string, pages: Dict<PageRegistration>) {
  const firstSegment = '/' + pathname.split('/')[1];
  if (firstSegment == '/') {
    return null;
  }

  for (const [key, value] of Object.entries(pages)) {
    if (key.startsWith(firstSegment)) {
      return value.pilet;
    }
  }
  return '';
}

export const PiletOnlyMenu: React.FC<MenuProps> = ({ type = 'sidebar' }) => {
  const pages = useGlobalState(s => s.registry.pages);
  const menuItems = useGlobalState(s => s.registry.menuItems);
  const location = useLocation();

  const piletName = useMemo(() => {
    return getPiletName(location.pathname, pages);
  }, [location.pathname, pages]);

  const renderedItems = useMemo(() => {
    if (!piletName) return [];

    const itemsToRender = Object.keys(menuItems)
      .filter(name => menuItems[name].settings.type === type)
      .filter(name => menuItems[name].pilet.includes(piletName))
      .map(name => ({
        name,
        Component: menuItems[name].component,
        meta: menuItems[name].settings,
      }))
      .map(({ name, Component, meta }) => (
        <PiralMenuItem key={name} type={type} meta={meta}>
          <Component />
        </PiralMenuItem>
      ));

    return itemsToRender;
  }, [menuItems, piletName]);

  return piletName && renderedItems;
};
PiletOnlyMenu.displayName = 'PiletOnlyMenu';
